<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ item.code }}
    </v-card-title>

    <v-card-text>
      <div class="font-weight-medium purple--text">Nhận đơn từ KHO</div>
      <v-row dense>
        <v-col
          cols="6"
          v-for="warehouse in warehouseOptions"
          :key="`fw_${warehouse.value}`"
        >
          <v-checkbox
            v-model="allowWarehouses"
            class="mt-1 ml-0"
            :value="warehouse.value"
            :label="warehouse.text"
            hide-details
            dense
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.close") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="saveConfig">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "WarehouseConfig",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    allowWarehouses: [],
  }),
  computed: {
    warehouseOptions() {
      const { warehouses } = window.me || { warehouses: [] };
      return [...warehouses]
        .filter((w) => w.id !== this.item.id)
        .map((w) => ({
          text: w.code_name,
          value: w.id,
        }));
    },
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async saveConfig() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-warehouse-transfer-pos-config", {
          id_pos: this.item.id,
          allow_id_warehouses: this.allowWarehouses,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getConfig() {
      httpClient
        .post("/get-warehouse-transfer-pos-config", { id_pos: this.item.id })
        .then(({ data }) => {
          this.allowWarehouses = [...data.allowItems]
            .filter((item) => !!item.allow_id_warehouse)
            .map((item) => item.allow_id_warehouse);
        });
    },
  },
};
</script>

<style scoped></style>
